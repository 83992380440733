/**
 * utility for http ajax interaction
 * @type object
 */

const httpClient = {
    ajax(params) {
        const options = { ...params };
        options.dataType = options.dataType || 'json';
        return $.ajax(options);
    },

    signOut(){
        const url = '/auth/sign_out';
        const payload = {
            url,
            type: 'DELETE',
            dataType: 'html'
        };
        return httpClient
            .ajax(payload)
            .done((xhr) => {
                window.location.href = '/';
        });
    }
};

export default httpClient;
