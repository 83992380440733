/**
 * utility for http ajax interaction
 * @type object
 */

const populateTableHelper = {
    populateTable(tableId, tableData, summaryPage = true) {
        const table = document.getElementById(tableId);
        let row = '';
        self = this;
        tableData["data"].forEach(function(datum){
            let cols = '';
            for (let i = 0; i < 8; i++) {
                if (summaryPage && i === 2){
                    continue;
                }
                const key = `v0${i+1}`;
                if (datum.hasOwnProperty(key)) {
                    let colNode = document.createElement('td');
                    colNode.classList.add(key);
                    colNode.innerText =  datum[key]['value'];
                    if(i === 0){
                        // Color code first col
                        populateTableHelper.colorCodeLoadPoint(Number(datum['v06']['value']), Number(datum['v05']['value']), colNode);
                    }
                    if(summaryPage && i === 5){
                        // If summary page then color code `load value`
                        // Color code as green if machine is loading or unloading and keep it normal color if its stopped
                        populateTableHelper.colorCodingWinchStatus(colNode, (datum['v03']['value'] !== 0));
                    }
                    if(!summaryPage && i === 2){
                        // If details view page then color code `winch status` coz it exists
                        // Color code as green if machine is loading or unloading and keep it normal color if its stopped
                        const winchStatusMap = { 0: 'Stopped', 1: 'Loading', 2: 'Unloading' };
                        populateTableHelper.colorCodingWinchStatus(colNode, (datum['v03']['value'] !== 0), winchStatusMap[datum['v03']['value']]);
                    }
                    cols += colNode.outerHTML;
                }
            }
            row += `<tr>${cols}</tr>`;
        });
        table.lastElementChild.innerHTML = row;
    },

    colorCodeLoadPoint(loadValue, setPoint, colNode){
        // If Load Value is Less than set point then set color as red
        if(loadValue < setPoint){
            colNode.classList.add('has-background-danger');
            colNode.classList.add('has-text-white');
        } // If Load Value is greater than set point then set color as yellow
        else if(loadValue > setPoint){
            colNode.classList.add('has-background-warning');
            colNode.classList.add('has-text-black');
        }
        else {
            colNode.classList.add('has-text-black');
        }
    },

    colorCodingWinchStatus(colNode, cellColorGreen = undefined, text = undefined){
        if(cellColorGreen){
            colNode.classList.add('has-background-primary');
        }
        if (text) {
            colNode.innerText = text;
        }
    },
};


export default populateTableHelper;
