import httpClient from "./httpClient";

/**
 * utility for http ajax interaction
 * @type object
 */

const errorMsg = {
    showErrorModal(target, json, name_target = null, sign_out = true){
        document.getElementById(target).classList.add('is-active');
        if(name_target !== null) {
            document.getElementById(name_target).innerText = json['first_name'];
        }
        if (sign_out) {
            setTimeout(function () {
                httpClient.signOut();
            }, 6000);
        }
    },

    hideErrorModal(target){
        document.getElementById(target).classList.remove('is-active');
    },

    showGeneralError(message){
        const div = document.getElementById('show-error-msg');
        div.classList.add('has-text-danger');
        div.classList.add('has-text-centered');
        div.innerText = '';
    }

};

export default errorMsg;
