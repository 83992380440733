// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import httpClient from "../utils/httpClient";
import populateTableHelper from "../utils/populateTableHelper";
import errorMsg from "../utils/errorMsg";

export default class extends Controller {
    static targets = [ "setPoint", "loadAxis", "longitudinal", "transverse",
                        "vertical", "tab"];

  connect() {
    const loadAxis = this.loadAxisTarget.innerText.toLocaleLowerCase();
    // activate tab based on current load axis
    if(loadAxis == 'longitudinal'){
        this.longitudinalAction();
    }
    else if(loadAxis == 'transverse'){
        this.transverseAction();
    }
    else{
        this.verticalAction();
    }
    const self = this;
    self.fetchLoadData(loadAxis);
  }

  disconnect() {
      this.stopPolling();
  }

    fetchLoadData(loadAxis) {
    const url = '/dashboard/fetch_load_data';
    const data = { load_axis: loadAxis, fields_to_hide: 'v09', sort_by: 'v09' };
    const payload = {
      url,
      type: 'GET',
      data
    };
    const self = this;
    httpClient
        .ajax(payload)
        .done((data) => {
            errorMsg.hideErrorModal('modal-target-2');
            errorMsg.showGeneralError( "");
            self.updateData('single-load-table', data[loadAxis]);
            self.populateHeaderData(data[loadAxis]['header']);
            self.timeout = setTimeout(() => {self.fetchLoadData(loadAxis)}, 950);
        })
        .fail((xhr) => {
            if (xhr.status === 401 && xhr.responseJSON["error_type"] === 'AccountExpiredError'){
                self.stopPolling();
                errorMsg.showErrorModal('modal-target-2', xhr.responseJSON, 'modal-name');
            }
            else{
                errorMsg.showGeneralError("Oops! Couldn't fetch data from server, please contact the admin.");
                self.timeout = setTimeout(() => {self.fetchLoadData(loadAxis)}, 950);
            }
        });
  }

  stopPolling(){
      if (!!this.timeout){
          clearTimeout(this.timeout);
      }
  }

  populateHeaderData(header){
      this.setPointTarget.textContent = `Set Point :${header['stpt']}`;
      document.getElementById('tower-name').textContent = `Tower Type :${header['tower']}`;
      document.getElementById('tower-data').textContent = `${header['case']}`;
      document.getElementById('timer').textContent = `Timer : ${header['tmin']}:${header['tsec']}`;
  }

  updateData(tableId, tableData){
      populateTableHelper.populateTable(tableId, tableData, false)
  }

  longitudinalAction(){
      this.switchTabs(this.longitudinalTarget);
  }
  transverseAction(){
      this.switchTabs(this.transverseTarget);
  }
  verticalAction(){
      this.switchTabs(this.verticalTarget);
  }

  switchTabs(targetToActivate){
      this.removeClassOnArray(this.tabTarget.children, 'is-active');
      targetToActivate.classList.add('is-active');
  }

  removeClassOnArray(nodeList, classToRemove){
      for (let i = 0; i < nodeList.length; i++) {
          nodeList.item(i).classList.remove(classToRemove);
      }
  }

}
