// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import httpClient from "../utils/httpClient";
import populateTableHelper from "../utils/populateTableHelper";
import errorMsg from "../utils/errorMsg";

export default class extends Controller {
  static targets = [ "userRandomId"];

  connect() {
    this.players = [];
    const self = this;
    const playerData =  [
        {name: 'canvas-1', socketName: 'websock_a'},
        {name: 'canvas-2', socketName: 'websock_b'}
        // {name: 'canvas-3', socket_name: 'websock_c'}
        ];
    setTimeout(function(){
        playerData.forEach(function(item){
            self.drawPlayer(item);
        });
    }, 2000);

    self.fetchLoadData();
  }

  disconnect() {
      this.stopPolling();
      this.players.forEach(function(item){
          item.source.destroy();
      });
  }

    fetchLoadData() {
        const url = '/dashboard/fetch_load_data';
        const data = { load_axis: 'all', fields_to_hide: 'v02,v07,v08,v09', sort_by: 'v09' };
        const payload = {
          url,
          type: 'GET',
          data
        };
        const self = this;
        httpClient
            .ajax(payload)
            .done((data) => {
              errorMsg.hideErrorModal('modal-target-2');
              errorMsg.showGeneralError( "");
              ['vertical-loading', 'transverse-loading', 'longitudinal-loading'].forEach(function(loadType){
                  populateTableHelper.populateTable(loadType, data[loadType.split('-')[0]], true);
              });
              // Any one can be picked
              self.populateHeaderData(data);
              self.timeout = setTimeout(() => {self.fetchLoadData()}, 950);
            })
            .fail((xhr) => {
                if (xhr.status === 401 && xhr.responseJSON["error_type"] === 'AccountExpiredError'){
                    self.stopPolling();
                    errorMsg.showErrorModal('modal-target-2', xhr.responseJSON, 'modal-name');
                }
                else{
                    errorMsg.showGeneralError( "Oops! Couldn't fetch data from server, please contact the admin.");
                    self.timeout = setTimeout(() => {self.fetchLoadData()}, 950);
                }
            });
  }

  stopPolling(){
      if (!!this.timeout){
          clearTimeout(this.timeout);
      }
  }

  populateHeaderData(headerData){
      const header = headerData['vertical']['header'];
      document.getElementById('stpt-vertical').textContent = `Set Point :${headerData['vertical']['header']['stpt']}`;
      document.getElementById('stpt-transverse').textContent = `Set Point :${headerData['transverse']['header']['stpt']}`;
      document.getElementById('stpt-longitudinal').textContent = `Set Point :${headerData['longitudinal']['header']['stpt']}`;
      document.getElementById('tower-name').textContent = `Tower Type :${header['tower']}`;
      document.getElementById('tower-data').textContent = `${header['case']}`;
      document.getElementById('timer').textContent = `Timer : ${header['tmin']}:${header['tsec']}`;
  }

  maximize(event){
      event.target.id;
      const socketMap = {
          'canvas-1': 'websock_a',
          'canvas-2': 'websock_b'
          // 'canvas-3': 'websock_c'
      };
      const canvas = document.createElement('canvas');
      canvas.id = `${event.target.id}-max`;
      document.getElementById('modal-player').appendChild(canvas);
      this.drawPlayer({ name: canvas.id, socketName: socketMap[event.target.id] });
      document.getElementById('video-modal').classList.add('is-active');
  }

  drawPlayer(item){
      this.players.push(
          new JSMpeg.Player(`wss://tt.kecrpg.com/${item.socketName}?random_id=${this.userRandomIdTarget.dataset.randomid}`, {canvas: document.getElementById(item.name), autoplay: true})
      );
  }

  closeModal(){
      document.getElementById('modal-player').innerHTML = "";
      document.getElementById('video-modal').classList.remove('is-active');
  }
}
